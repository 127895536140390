import React, { Component, Fragment, useState } from "react";
import { Redirect, Route, Link } from 'react-router-dom';
import UserService from "../services/user.service";
import "../assets/css/crops.css";
import MaterialTable from "material-table";
import tableIcons from './icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {TriggerAlert,} from './dryfunctions'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthService from "../services/auth.service";
import { makeStyles } from '@material-ui/core/styles';

import { faHtml5, faHome } from "@fortawesome/free-solid-svg-icons";
import {
    
    Row,
    Col,
    Form
} from "react-bootstrap";
//have used class component . Initially we are defining all the state variables which are used inside the component.

export default class FpoFarmer extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
            fpofarmercounts: [],
            loading: false,
            showloader:true,
            SelOrg:'all',
            getProjectList:[],
            isManagerlogged:false,
            
        };
    }
     // on clicking on FPO Name in pivot table this function is trigerred . Here we are storing the fpoName and fpoId in localStorage
     //then with that fpoName we are navigating to different component and changing the URL also.
    navigateToPage= (pageName,fpoId,fpoName) => {
    localStorage.setItem("fpoId", JSON.stringify(fpoId));
    localStorage.setItem("fpoName", JSON.stringify(fpoName));

   
    this.props.history.push(
      "/farmerlist/" +
        fpoName
    );
     
    };
        //this function will navigate to Organization Dahboard.

    navigateToPage2= (pageName) => {
       
      this.props.history.push("/" + pageName + "");
    };
    handleOrgChange = (e) => {
      const selectedOrgName = e.target.value;
      
      // Find the selected organization by name
      const selectedOrg = this.state.getProjectList.find(org => org.name === selectedOrgName);
      
      // Update state with selected organization details
      this.setState({
        SelOrg: selectedOrgName, // Store the selected name
        SelOrgId: selectedOrg ? selectedOrg.id : null, // Store the selected id
        fpoFIcounts: [],
        showloader: true,
        
      });
    
      // Call fetchCropsCount with the selected organization's id
      this.fetchFarmerCount(selectedOrg ? selectedOrg.id : 'all');
    }
    

    fetchFarmerCount =(org)=>{
      var flag = false;
      this.setState({
        showloader:true,
        fpofarmercounts:[]
      })

      UserService.getFpoFarmersCount(org).then(

        (response) => {

            flag = true; 
            if (response.data.success) {
                this.setState({
                    showloader: false,
                    fpofarmercounts: response.data.fpo_farmer_data
                });
            }
            else {
                this.setState({
                    showloader: false,
                });
                if (response){
                    TriggerAlert("Error",response.data.message,"error");
                  }
                  else {

                    TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
                  }
            }
        },
        (error) => {
            flag = true;
            this.setState({
                showloader: false,
                content:
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString(),
            });
            if (error.response){
                TriggerAlert("Error",error.response.data.message,"error");
              }
              else {

                TriggerAlert("Error","Server closed unexpectedly, Please try again","error");
              }
        },
        setTimeout(() => {
            if(flag==false){
                this.setState({
                    showloader:false,
                });
            TriggerAlert("Error","Response Timed out, Please try again","info");
            this.props.history.push('/fpohomeData')             
            }
        }, 30000)
        
    );

    }

    //in componentDidMount initially we are checking if it is a valid user or not .Then we are checking if valid user is parent or not then accordinly we are 
    // navigating to the component. Then we are calling the API and fetching the data and if API returns error then we are displaying an error messge .
    componentDidMount() {
     
      const user = AuthService.getCurrentUser();
      
      if (user.is_project_manager) {
            this.setState({
             isManagerlogged:true
                      })
             }

      if(!user){
        this.props.history.push('/')
        return
      }
      if(!user.is_parent && !user.is_project_manager)
        this.props.history.push("/dashboard")
      
        var flag = false;
 
        this.fetchFarmerCount('all')
       
        UserService.getProjectList().then(
          (response) => {
              flag = true;
              // var dateDict = {};
              // response.data.date_range.map((date) => {
              //     dateDict[date] = date;
              // });
              
              this.setState({
                  
                getProjectList: response.data.data== null ? [] : response.data.data,
                
                  showloader: false,
              });
          },
          (error) => {
              flag = true;
              this.setState({
                  showloader: false,
                  content:
                      (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                      error.message ||
                      error.toString(),
              });
              if (error.response) {
                  TriggerAlert("Error", error.response.data.message, "error");
              } else {
                  TriggerAlert(
                      "Error",
                      "Server closed unexpectedly, Please try again",
                      "error"
                  );
              }
          },
          setTimeout(() => {
              if (flag == false) {
                  this.setState({
                      showloader: false,
                  });
                  TriggerAlert("Error", "Response Timed out, Please try again", "info");
                  this.navigateMainBoard()
              }
          }, 30000)
      )
    }

    
  //rendering the materialTable
    render() {
      

        const { fpofarmercounts, showloader, SelOrg , getProjectList} = this.state;
        
        const columns = [
          
            {  
             
                title: "FPO Name",
                field: "farmer_fpo__name",
                headerStyle:{
               
                  position:"sticky",
                  top:0,
                  zIndex: 1000,
              
                },
                cellStyle: {
                  position: '-webkit-sticky',
                  position: 'sticky',
                  background: '#f1f1f1',
                  left: 0,
                  zIndex: 1,


                  // overflow:"hidden",
                  // color: '#FFF',
                  // position: 'sticky',
                  // left: 0,
                  minWidth: 300,
                  maxWidth: 300
                },

               
                render: (rowData) => {
                  let fpoId = rowData.farmer_fpo;
                  let fpoName = rowData.farmer_fpo__name;

                  return (
                    <div
                      onClick={() =>
                        this.navigateToPage("farmerlist",fpoId, fpoName)
                         
                      }
                    >
                      <a href="#!">{rowData.farmer_fpo__name}</a>
                    </div>
                  );
        
                    }
                  
            },
            {
                title: "Block",
                field: "fpo_block",
                width: 150,
                cellStyle: {
                  position: '-webkit-sticky',
                  position: 'sticky',
                  background: '#f1f1f1',
                  left: 0,
                  zIndex: 1,
                  minWidth: 200,
                  maxWidth: 200,
                },
              //  width:"5%",
            },
            {
                title: "District",
                field: "fpo_dist",
                width: 150,
                cellStyle: {
                  position: '-webkit-sticky',
                  position: 'sticky',
                  background: '#f1f1f1',
                  left: 0,
                  zIndex: 1,
                  minWidth: 200,
                  maxWidth: 200,
                },
                // width:"5%",
            },
            {
                title: "State",
                field: "fpo_state",
                width: 150,
                cellStyle: {
                  position: '-webkit-sticky',
                  position: 'sticky',
                  background: '#f1f1f1',
                  left: 0,
                  zIndex: 1,
                  minWidth: 200,
                  maxWidth: 200,
                },
                // width:"5%",
            },
            {
                title: "Total Farmers",
                field: "total_farmers",
                filtering:false,
                cellStyle: {
                  position: '-webkit-sticky',
                  position: 'sticky',
                  background: '#f1f1f1',
                  left: 0,
                  zIndex: 1,
                  minWidth: 200,
                  maxWidth: 200,
                },
                width: 150,
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.total_farmers || 0;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
                // cellStyle: {
                //   borderRight: '1px solid black',
                // },
                // width:"5%",
            },
            {
                title: "Member Farmers",
                field: "member_farmers",
                filtering:false,
                cellStyle: {
                  position: '-webkit-sticky',
                  position: 'sticky',
                  background: '#f1f1f1',
                  left: 0,
                  zIndex: 1,
                  minWidth: 200,
                  maxWidth: 200,
                },
                width: 150,
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.member_farmers || 0;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
                // width:"5%",
            },
            {
                title: "Non Member Farmers",
                field: "non_member_farmers",
                filtering:false,
                width: 150,
                cellStyle: {
                  position: '-webkit-sticky',
                  position: 'sticky',
                  background: '#f1f1f1',
                  left: 0,
                  zIndex: 1,
                  minWidth: 200,
                  maxWidth: 200,
                },
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.non_member_farmers || 0;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
                // cellStyle: {
                //   borderRight: '1px solid black',
                // },
                // width:"5%",
            },
            {
                title: "Farmers with Land",
                field: "farmers_with_land",
                filtering:false,
                width: 150,
                cellStyle: {
                  position: '-webkit-sticky',
                  position: 'sticky',
                  background: '#f1f1f1',
                  left: 0,
                  zIndex: 1,
                  minWidth: 200,
                  maxWidth: 200,
                },
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.farmers_with_land || 0;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
                // width:"5%",
            },
            {
                title: "Landless Farmers",
                field: "landless_farmers",
                filtering:false,
                cellStyle: {
                  position: '-webkit-sticky',
                  position: 'sticky',
                  background: '#f1f1f1',
                  left: 0,
                  zIndex: 1,
                  minWidth: 200,
                  maxWidth: 200,
                },
                width: 150,
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.landless_farmers|| 0;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
                // cellStyle: {
                //   borderRight: '1px solid black',
                // },
                // width:"5%",
            },
            {
                title: "KYC Completed",
                field: "kyc_completed",
                cellStyle: {
                  position: '-webkit-sticky',
                  position: 'sticky',
                  background: '#f1f1f1',
                  left: 0,
                  zIndex: 1,
                  minWidth: 200,
                  maxWidth: 200,
                },
                filtering:false,
                width: 150,
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.kyc_completed || 0;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
                // width:"5%",
            },
            {
                title: "KYC incomplete",
                field: "kyc_not_completed",
                cellStyle: {
                  position: '-webkit-sticky',
                  position: 'sticky',
                  background: '#f1f1f1',
                  left: 0,
                  zIndex: 1,
                  minWidth: 200,
                  maxWidth: 200,
                },
                filtering:false,
                width: 150,
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.kyc_not_completed || 0;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
                // cellStyle: {
                //   borderRight: '1px solid black',
                // },
                // width:"5%",
               
            },
            {
                title: "Farmers with Agri and Allied",
                field: "allied_activity_farmers",
                cellStyle: {
                  position: '-webkit-sticky',
                  position: 'sticky',
                  background: '#f1f1f1',
                  left: 0,
                  zIndex: 1,
                  minWidth: 200,
                  maxWidth: 200,
                },
                filtering:false,
                width: 150,
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.allied_activity_farmers || 0;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
                // cellStyle: {
                //   borderRight: '1px solid black',
                // },
                // width:"5%",
               
            },
            {
                title: "Farmers with Dairy",
                field: "dairy_farmers",
                cellStyle: {
                  position: '-webkit-sticky',
                  position: 'sticky',
                  background: '#f1f1f1',
                  left: 0,
                  zIndex: 1,
                  minWidth: 200,
                  maxWidth: 200,
                },
                filtering:false,
                width: 150,
                render: (rowData) => {
                  // Check if profits exist and format them, otherwise return 0 formatted
                  const profits = rowData.dairy_farmers || 0;
                  return new Intl.NumberFormat('en-IN').format(profits);
              },
                // width:"5%",
               
            }
            

        ];
        
            return (
              <section className="mainWebContentSection">
                
                <Fragment>
                  <div  className="breadcrumb pageBreadCrumbHolder landHoldingBreadCrumbWrap"> 
                  <a
                href="#"
                className="breadcrumb-item pageBreadCrumbItem"
                onClick={() =>this.navigateToPage2("fpohomeData")
                }
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
                  style={{ fontSize: "0.7rem" }}
                />
                &nbsp;Dashboard
              </a>
                  
                  
                  </div>
                  <div className="landholdingHeader wrap">
                    
                    <Row>
                      <Col lg="12" md="12" sm="12" className="noPadding">
                      <div className="padding15 fpoCropsHeading">
  <Row style={{ marginBottom: "20px", marginTop:"-20px" }}>
    <Col md={6}>
      <h4
        className="farmerListHeading dvaraBrownText"
        style={{ marginLeft: "30px", fontSize: "24px" , marginTop:"-80%",marginBottom:"10px"}}
      >
       {this.state.isManagerlogged ?"Farmers Data": "Organization Farmers Data" }
      </h4>
    </Col>
  </Row>
  <Row>
    {this.state.isManagerlogged ? (
      <Col className="seasonDropdown" md={8}>
        <Form>
          <Row style={{ marginTop: "-36px", marginLeft: "25px" }} className="align-items-center">
            <Col xs={12} sm={4} className="mb-2">
              <Form.Group>
                <Form.Label style={{ fontSize: "15px", color: "brown" }}>
                  Select Organization
                </Form.Label>
                <Form.Control
                  size="sm"
                  as="select"
                  value={SelOrg}
                  onChange={this.handleOrgChange}
                  style={{ border: "1px solid grey", color: "brown" }}
                >
                  <option value="all">All</option>
                  {getProjectList.map((org) => (
                    <option key={org.id} value={org.name}>
                      {org.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Col>
    ) : (
      ""
    )}
  </Row>
</div>

                        {showloader ? (
                          <div className="mainCropsFarmerLoaderWrap">

                            <span className="spinner-border spinner-border-lg mainCropsFarmerLoader"></span>
                          </div>
                        ) : (
                          <MaterialTable
                            icons={tableIcons}
                            title=""
                            style={{ marginLeft: "30px" , bottom:'30px', marginTop:"20px"}}
                            data={fpofarmercounts}
                            // data={[
                            //   {  id:"1",farmer_fpo__name: 'Mehmet', fpo_block: 'Baran', fpo_dist: 1987, fpo_state: 63 },
                            //   { id:"2",farmer_fpo__name: 'Mehmet22', fpo_block: 'Baran45', fpo_dist: 19878, fpo_state: 6378 },
                            // ]}      
                            columns={columns}
                            
                            actions={
                              [
                                // {
                                //     icon: tableIcons.Edit,
                                //     tooltip: 'Edit',
                                //     onClick: (event, rowData) => showModal(true, rowData)
                                // },
                                // rowData => ({
                                //     icon: tableIcons.Delete,
                                //     tooltip: 'Delete',
                                //     isFreeAction: true,
                                //     onClick: (event, rowData) => {if(window.confirm('Are you sure to delete this "'+ rowData.wsp + '" record?'))
                                //                             {this.createWsp(rowData.id)}},
                                //   })
                              ]
                            }
                            options={{
                             
                              // fixedColumns: {
                              //   left: 1,
                              // },
                              maxBodyHeight:500,
                              // display:"scroll",
                              actionsColumnIndex: -1,
                              doubleHorizontalScroll: true,
                              pageSize: 10,
                              pageSizeOptions: [
                                10,
                                20,
                                50,
                                100,
                                { value: fpofarmercounts.length, label: "All" },
                              ],
                              exportButton: true,

                              headerStyle: {
                                backgroundColor: "#A3C614",
                                color: "#fff",
                                fontSize: "1.2rem",
                                fontFamily: "barlow_reg",
                                fontWeight: "bold",
                                
                                position: 'sticky',
                                top:0,
                                overflow:"hidden",
                                left: 0,
                              },
                              rowStyle: {
                                backgroundColor: "#f1f1f1",
                                borderBottom: "2px solid #e2e2e2",
                                fontSize: "0.9rem",
                              },
                              filtering: true,
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="verticalSpacer20"></div>
                </Fragment>
              </section>
            );
        
    }
}